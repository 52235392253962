<template>
 <div class="row" v-if="displayPdtPage">
  <div class="col-lg-8">
    <div class="iq-card iq-card-block iq-card-stretch iq-card-height blog blog-detail">
      <div class="iq-card-body">
        <div class="image-block">
          <img v-if="quizDetail && quizDetail.app_image" :src="quizDetail.app_image" class="img-fluid rounded w-100" alt="blog-img" />
        </div>

        <div class="blog-description mt-2">
          <div>
            <h5 class="mb-3 pb-3 border-bottom">
              {{ quizDetail && quizDetail.app_name ? quizDetail && quizDetail.app_name : "" }}
            </h5>
            <b-btn v-if="userData.user_role=='USERROLE11111' || userData.user_role=='USERROLE11118'" class="right_button mr-3 pull-right" variant="primary" size="sm" @click="productEdit()">
              Edit
            </b-btn>
            <b-btn v-if="showGoAhead" class="right_button mr-3 pull-right" variant="primary" size="sm" @click="goToTestPrepModules()">
              Go Ahead
            </b-btn>
            <b-btn v-if="userData.user_role=='USERROLE11111' || userData.user_role=='USERROLE11118'" class="right_button mr-3 pull-right" variant="primary" size="sm" @click="goToGidePrepDashboard()">
              TestSeries Dashboard
            </b-btn>
            <b-btn class="right_button mr-3" variant="primary" size="sm" @click="goToSpecificPdtPage(TestSeriesConfigJson.TESTSERIES_SAT_PRODUCT_PAGE_APP_ID)">
              SAT
            </b-btn>
            <b-btn class="right_button mr-3" variant="primary" size="sm" @click="goToSpecificPdtPage(TestSeriesConfigJson.TESTSERIES_CUET_SCI_PRODUCT_PAGE_APP_ID)">
              CUET-SCI
            </b-btn>
            <b-btn class="right_button mr-3" variant="primary" size="sm" @click="goToSpecificPdtPage(TestSeriesConfigJson.TESTSERIES_CUET_COMM_PRODUCT_PAGE_APP_ID)">
              CUET-COMM
            </b-btn>
            <b-btn class="right_button mr-3" variant="primary" size="sm" @click="goToSpecificPdtPage(TestSeriesConfigJson.TESTSERIES_CUET_HUM_PRODUCT_PAGE_APP_ID)">
              CUET-HUM
            </b-btn>
          </div>

          <div class="row blog-meta d-flex align-items-center mb-3">
            <div v-if="showErrMsg" class="mt-2 ml-2 primary-color">
              {{ showErrMsg }}
              <b-btn class="ml-3" variant="primary" size="sm" @click="UpdateProfile">Update Profile</b-btn>
            </div>
          </div>

          <p v-if="quizDetail && quizDetail.app_description" class="vhtmlTag vueHtmlParent mt-3" v-html="quizDetail.app_description"></p>
        </div>
      </div>
   </div>
  </div>

  <!-- Upcomming events -->
  <div class="col-lg-4 gide_right_mobile_data">
   <EventHomeList v-if="!sideBarItemsList.Activities.exludeUserRole.includes(userData.user_role)" />
   <CareerHomeList v-if="!isMobileDevice" />
  </div>

  <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
   <div v-html="toastMsg">
   </div>
  </b-toast>

  <AlertBox :key="showUpgradeAlertBox" v-if="showUpgradeAlertBox" :propAlertTitle="alertTitle"
   :propAlertDesc="alertDesc" :propOkBtnText="okText" :prophidebutton="true" :propCancelBtnText="cancelText"
   @emitCloseAlertModel="showUpgradeAlertBox = false" @emitConfirmAlertModel="goToEventPlanList()" />
 </div>
</template>

<script>
import { socialvue } from "../../../config/pluginInit"
import AlertBox from "../../../components/AlertBox.vue"
import CareerHomeList from "../ListPages/Career/CareerHomeList.vue"
import EventHomeList from "../Event/EventHomeList.vue"
import { Apps } from "../../../FackApi/api/apps.js"
import SideBarMenu from "../../../Utils/sidebarMenu.js"
import TestSeriesConfigJson from "../../../FackApi/json/TestSeries.json"
import { User } from "../../../FackApi/api/user"

export default {
  name: "TPrepProduct",
  components: {
    AlertBox,
    EventHomeList,
    CareerHomeList
  },
  data () {
    return {
      TestSeriesConfigJson: TestSeriesConfigJson,
      cvTitle: "TestSeries",
      isMobileDevice: window.__IS__MOBILE_DEVICE__,
      toastVariant: "",
      showToast: false,
      toastMsg: "",
      toastTitle: "TestSeries",
      showErrMsg: null,
      showUpgradeAlertBox: false,
      alertDesc: "",
      alertTitle: "",
      okText: "Upgrade",
      cancelText: "Not now",
      source: "ONLINE",
      session_state: null,
      quizDetail: null,
      showActionBtns: true,
      app_id: TestSeriesConfigJson.TESTSERIES_PRODUCT_PAGE_APP_ID,
      sideBarItemsList: SideBarMenu.getSideBarMenuObjList(),

      tprepSubsPlanList: [],
      openPayw: false,
      tprepEventId: "",
      showGoAhead: false,
      displayPdtPage: false,
      userSubjectInterest: null
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  async mounted () {
    socialvue.index()
    await this.getUserSubjectInterest()
    this.appView()
  },
  methods: {
    /**
     * getUserSubjectInterest
     */
    async getUserSubjectInterest () {
      try {
        const userInterestListResp = await User.userInterestList(this, this.userData.user_id)
        if (!userInterestListResp.resp_status && userInterestListResp.resp_code != "ERR_DATA_NOT_FOUND") {
          // The interest entry gets created at the time of quick profile completion so if the interests are not found, it means there is an exception in the api
          this.displayPdtPage = true
          this.toastMsg = "Exception in viewing the user's interest"
          this.toastVariant = "danger"
          this.showToast = true
          return
        }
        else if (userInterestListResp.resp_code == "ERR_DATA_NOT_FOUND") {
          this.displayPdtPage = true
          return
        }

        if (userInterestListResp.resp_data.interest_list.INTERESTCAT11117) {
          this.userSubjectInterest = userInterestListResp.resp_data.interest_list.INTERESTCAT11117.map(e => e.interest_id)

          this.userSubjectInterest = this.userSubjectInterest.filter((subjectInterest) => {
            return this.TestSeriesConfigJson.TESTSERIES_AVAILABLE_EXAMS.find(examName => subjectInterest.includes(examName))
          })
          if (this.userSubjectInterest.length > 0) {
            if (this.$route.query.edit) {
              // Admin wants to view/edit the product page so do not redirect to testseries student dashboard
              this.displayPdtPage = true
              return
            }
            // Redirect the user to the testseries dashboard
            this.$router.push("/testseries")
          }
          else {
            this.displayPdtPage = true
          }
        }
        else {
          // No interest found for testseries
          this.displayPdtPage = true
        }
      }
      catch (err) {
        console.error("Exception in getUserSubjectInterest and err: ", err)
      }
    },
    /**
     * productEdit
     */
    productEdit () {
      window.open(`quiz_edit/${this.app_id}`, "_blank")
    },
    /**
     * UpdateProfile
     */
    UpdateProfile () {
      window.open(`/user_edit/${this.userData.user_id}`, "_blank")
    },
    /**
     * Get Info
     */
    async appView () {
      try {
        this.quizId = this.app_id
        const quizResp = await Apps.appView(this, this.quizId)
        this.quizDetail = quizResp.resp_data.data
      }
      catch (error) {
        console.error("Exception in appview()", error.message)
      }
    },
    /**
     * goToSpecificPdtPage
     */
    goToSpecificPdtPage (pdtId) {
      this.$router.push(`test_prep_target/${pdtId}`)
    },
    /**
     * goToGidePrepDashboard
     */
    goToGidePrepDashboard () {
      this.$router.push("/testseries_dashboard")
    },
    /**
     * goToTestPrepModules
     */
    goToTestPrepModules () {
      this.$router.push("/testseries")
    }
  }
}
</script>
